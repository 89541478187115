import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I love this place, in fact it’s probably my favorite place on earth. It’s the place that I spend all my free time. Laying down under this tree. This tree has something special in it. Every time I lay here I see something new. Something that makes this tree even more special and unique. Every time I come here I just forget everything and start looking at the tree. Closer and closer every time. I’ve always dreamt to live under this my whole life but unfortunately that’s not how life works and I have to go to school and learn things that will be “useful” in life. However, I like school sometimes; I have a couple of friends that I can play with at break and even some classes are fun. I have a friend named Moritz, he is really nice and very funny. He is my favorite person ever, apart from my family of course. But there was nothing like my tree. He was my best friend. He was always here for me whether I was in a good mood or in a bad mood. Sometimes I’m cold but when I lay down under the tree, it’s so warm that I feel like I’m laying in my bed under a blanket.`}</p>
    <p>{`Today was a pretty good day, I was relaxing under my tree after school and I was starting to realise something about the tree; something that I felt could make me love this tree even more. But then I suddenly felt like something was wrong.There was something that felt very wrong. So I started moving positions until I finally found a comfortable position. I was then devastated, I looked up and couldn’t see or feel that special something. I went around the tree multiple times to see the area in which I could feel the nice feeling and decided to go home and discover this the next day.`}</p>
    <p>{`I got here today and I instantly saw my friend who was leaning on my tree. At first I thought that he was just here to chill but then he started getting mad at me.`}</p>
    <p>{`He scram at me angrily, “How dare you be such a fake friend, you are leaving me for a stupid tree! Something that isn’t living, something that is completely irrelevant!”`}</p>
    <p>{`I replied,” How could you talk about my tree like that! If you can’t accept my love for this tree then you don’t deserve to be my friend!” There was a small pause, I could see that he wasn’t impressed, I wanted to say something but before I could start, Moritz interrupted me,`}</p>
    <p>{`“I don’t want to be your friend anymore, if you don’t want to hangout with people that you call a friend then you better stay under this tree for the rest of your life! I don’t want to see you ever again!”`}<br parentName="p"></br>{`
`}{`I wanted to change something, but it was already too late, he was already gone.`}</p>
    <p>{`After that discussion I was really sad so I went to lie down under the tree and I made sure to lie down in the area with a special feeling. I wanted to reflect and think about what just happened. I hadn’t really thought about what he was saying because I was more concentrated on the words that he said about my tree. As I thought about it I started realising that he might have been right. I always say I love my family and that I love Moritz but I never spend time with the people that I love outside of school. I started to think about what I could do because abandoning my tree was not an option. It has been my life since I’m born. I couldn’t leave it. Then an idea came to my mind, I could spend some time after school with my friends/family and at the same time spend time under my favorite tree. After this decision I instantly went to Moritz’s house to explain myself.`}</p>
    <p>{`As soon as I got there I said, “Moritz I’m really sorry for all the past things that I have done. When you came to tell me that I was just offended by what you had said about my tree so I couldn’t think about what you actually were saying.”`}</p>
    <p>{`I hadn’t finished when he interrupted and said, “ I don’t care now, I don’t want to see you anymore, go spend time with your tree if you love him more than me.”`}<br parentName="p"></br>{`
`}{`I was really disappointed at what he said but I couldn’t give up, he had been my friend for over 5 years so I replied,`}</p>
    <p>{`“I really do enjoy being with you, I really regret having made you go through this. I have made a decision to start spending time with the people that are important to me and be with my tree only when I have spent time with all my favorite people.”`}</p>
    <p>{`I saw him let out a smile. Instantly I was relieved.`}</p>
    <p>{`Then he said, “Thank you for understanding my situation, I would have really missed not being with you at school but I really wanted to spend more time with you after school. I hope you aren’t too disappointed to leave your tree for too long.”`}</p>
    <p>{`We both laughed and went to play outside.`}</p>
    <p>{`After that day me and Moritz went to play after school everyday and enjoyed our time together. I thought that making this decision would separate me and my tree forever but it never happened. Everyday I continued going to my tree for 1 or 2 hours and continued discovering a lot of new things about it. I am now lying under my tree, the one that I have always loved.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      